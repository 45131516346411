@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Marcellus&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
